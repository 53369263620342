import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import svgNew from "../svg/categories/new.svg";
import svgDesign from "../svg/categories/design.svg";
import svgDev from "../svg/categories/dev.svg";
// import svgCollection from "../svg/categories/collection.svg";
// import svgSelf from "../svg/categories/self.svg";

const Nav = styled.nav`
  display: block;
  margin: 0;
  padding: 0 0 2em;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    padding: 1em 0;
  }
`;

const TagItemList = styled.ul`
  display: flex;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    margin: 0 -20px;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar {
      display: none;
    }
    &:after {
      content: "";
      width: 40px;
      flex: 0 0 auto;
    }
  }
`;

const TagItem = styled.li`
  width: 70px;
  margin: 0 20px 0 0;
  text-align: center;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 60px;
    flex: 0 0 auto;
    margin: 0 0 0 15px;
  }
  .cat-item__link {
    color: #fff;
  }

  .cat-item__image {
    padding: 2px;
    background: ${props => props.theme.colors.blackLight};
    border-radius: 50%;
    position: relative;
    img {
      position: relative;
      background: ${props => props.theme.colors.blackLight};
      border-radius: 50%;
      display: block;
      z-index: 2;
    }
  }
  .cat-item__name {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: ${props => props.theme.colors.gray};
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      font-size: 12px;
    }
  }
  &.active {
    .cat-item__image:after {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: ${props => props.theme.colors.gradient};
      animation: rotating 2s linear infinite;
    }
    img {
      border: solid 2px ${props => props.theme.colors.background};
    }
  }
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const TagLink = ({ name, icon, path }) => {
  const link = name === "" ? "/" : `/tags/${name}`;
  const isActive = link === path;

  return (
    <TagItem className={isActive && "active"}>
      <Link to={link} className="cat-item__link">
        <div className="cat-item__image">
          <img src={icon} alt={name} />
        </div>
        <div className="cat-item__name">{name}</div>
      </Link>
    </TagItem>
  );
};

const TagMenu = ({ location }) => {
  const path = location.pathname;
  const tags = [
    {
      name: "",
      icon: svgNew
    },
    {
      name: "Product",
      icon: svgDev
    },
    {
      name: "Slide",
      icon: svgDev
    },
    {
      name: "Golang",
      icon: svgDesign
    }
  ];

  return (
    <Nav>
      <TagItemList>
        {tags.map(tag => (
          <TagLink
            name={tag.name}
            icon={tag.icon}
            path={path}
          />
        ))}
      </TagItemList>
    </Nav>
  );
};

export default TagMenu;
