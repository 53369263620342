import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Wrapper = styled.div`
  .tag-text {
    display: inline;
    padding: 3px 6px;
    line-height: 1.2;
    font-size: 12px;
    border-radius: 2px;
    color: #fff;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 11px;
      padding: 2.5px 6px;
    }
  }
`;

const TagNames = ({ tags }) => {
  return (
    <Wrapper>
      {tags?.map(tag => (
        <Link
          to={`/tags/${tag}`}
          className="tag-text"
          style={{
            background: "#0c9ee4",
            marginRight: "5px"
          }}
        >
          {tag}
        </Link>
      ))}
    </Wrapper>
  );
};

export default TagNames;
